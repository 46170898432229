import React, { useEffect, useState } from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import { Link } from "gatsby"
import ContentContainer from ".//ContentContainer"
import { Container, Row, Col } from "styled-bootstrap-grid"

const FilterContainer = styled.div`
  width: 100%;
  background-color: ${colors.white};
  position: fixed;
  z-index: 1;
  display: none;
  max-width: 2000px;
  padding: 0 29px;


  @media (min-width: ${breakpoints.md}) {
    display: block;
    opacity: ${(props) => (props.visible ? "1" : "0")};
    top: 71px;
    z-index: ${(props) => (props.visible ? "1" : "-1")};
    transition: all 0.3s;
    padding: 0 50px
  }

`

const Filter = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledLink = styled(Link)`
  ${fonts.galvjiRegular};
  font-size: 14px;
  letter-spacing: 0.032px;
  line-height: 21px;
  color: ${colors.grey};
  text-decoration: none;
  margin-right: 30px;
  &.active {
    color: ${colors.darkGrey};
  }
`

const ProjectsCount = styled.div`
  ${fonts.galvjiRegular};
  font-size: 14px;
  letter-spacing: 0.032px;
  line-height: 21px;
  color: ${colors.grey};
  display: inline-block;
`

const Categories = styled.div`
  display: inline-block;
`

const CategoryFilter = ({ projectCount }) => {
  const [isAtTop, setIsAtTop] = useState(true)

  const categories = [
    { category: "Tous les projets", link: "/projets" },
    { category: "Résidentiel", link: "/residentiel" },
    { category: "Retail", link: "/retail" },
    { category: "Médical", link: "/medical" },
    { category: "Bureaux", link: "/bureaux" },
  ]

  const handleScroll = () => {
    let scrollTop = window.pageYOffset

    if (scrollTop >= 1) {
      setIsAtTop(false)
    }
    if (scrollTop === 0) {
      setIsAtTop(true)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll)
    }
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [isAtTop])

  return (
    <FilterContainer visible={!isAtTop}>
      <Container fluid>
          <Row>
            <Col>
              <Filter>
                <Categories>
                  {categories.map((category, index) => {
                    return (
                      <StyledLink
                        to={category.link}
                        activeClassName={"active"}
                        key={index}
                      >
                        {category.category}
                      </StyledLink>
                    )
                  })}
                </Categories>
                <ProjectsCount>{projectCount} Projets</ProjectsCount>
              </Filter>
            </Col>
          </Row>
      </Container>
    </FilterContainer>
  )
}

export default CategoryFilter
