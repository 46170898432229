import React from "react";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";

const Container = styled.div`
  width: 100%;

`;

const Content = styled.div`
  margin: auto;
  max-width: 2000px;
`;

const BlockContainer = ({children}) => {
  return (
    <Container>
      <Content>
        {children}
      </Content>
    </Container>
  );
};

export default BlockContainer;
