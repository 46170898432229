import React from "react";
import styled from 'styled-components';
import breakpoints from "../../styles/breakpoints";
import DarkTitle from "../Core/DarkTitle";
import UnderlineButton from "./UnderlineButton";

const PreFooterContainer = styled.div`
  margin: 100px 0 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  h3 {
    line-height: 24px;
  }
  
  @media(min-width: ${breakpoints.md}){
    margin: 130px 0 130px 0;
    h3 {
      line-height: 35px;
    }
  }
`;

const LinkContainer = styled.div`
  margin-top: 26px;
  
  @media(min-width: ${breakpoints.sm}){
    margin-top: 31px;
  }
`;

const PreFooter = () => {
  return (
    <PreFooterContainer>
      <DarkTitle>Vous avez un projet?</DarkTitle>
      <LinkContainer>
        <UnderlineButton to={"/contact"} lightGrey>
          <span>Contactez nous</span>
        </UnderlineButton>
      </LinkContainer>
    </PreFooterContainer>
  )
}

export default PreFooter;
