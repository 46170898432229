import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"
import styled from "styled-components"
import Intro from "../components/Work/Intro"
import Project from "../components/Work/Project"
import PreFooter from "../components/Core/PreFooter"
import breakpoints from "../styles/breakpoints"
import ContentContainer from "../components/Core/ContentContainer"
import { Container, Row, Col } from "styled-bootstrap-grid"
import fonts from "../styles/fonts"
import colors from "../styles/colors"
import CategoryFilter from "../components/Core/CategoryFilter"
import BlockContainer from "../components/Core/BlockContainer"

const WorkPageContainer = styled.div`
  margin-top: 142px;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 175px;
  }
`

const NoProject = styled.div`
  margin: 100px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  ${fonts.ralewayRegular};
  color: ${colors.grey};
  font-size: 14px;
  letter-spacing: 0.03px;
  line-height: 21px;
  @media (min-width: ${breakpoints.md}) {
    margin: 200px auto;
    font-size: 16px;
    line-height: 24px;
  }
`

const category = (props) => {
  const path = props.location.pathname
  const page = props.data.page
  const projects = props.data.projects.nodes;
  const category = page.category.title
  const filteredByCategory = projects.filter(
    (project) => project.category.title === category
  )
  let filteredProjects = [];
  let slugs = [];

  filteredByCategory.forEach((project) => {
    if (!slugs.includes(project.slug)) {
      slugs.push(project.slug)
      filteredProjects.push(project)
    }
  })

  const sortedProjects = filteredProjects.sort((a, b) => a.order - b.order);

  return (
    <Layout darkStyle={true}>
      <SEO
        fullTitle={true}
        path={path}
        title={page.metaTitle}
        description={page.metaDescription}
      />
      <CategoryFilter projectCount={sortedProjects.length} />
      <BlockContainer>
        <WorkPageContainer>
          <Container fluid>
            <ContentContainer>
              <Row>
                <Col md={5} mdOffset={1} xxlOffset={0}>
                  <Intro title={page.title} description={page.description} />
                </Col>
                <Col md={12}>
                  {sortedProjects.length > 0 ? (
                    sortedProjects.map((project, index) => {
                      return (
                        <Project
                          key={index}
                          project={project}
                          isEven={index % 2}
                        />
                      )
                    })
                  ) : (
                    <NoProject>
                      Coming Soon...
                    </NoProject>
                  )}
                  <PreFooter />
                </Col>
              </Row>
            </ContentContainer>
          </Container>
        </WorkPageContainer>
      </BlockContainer>
    </Layout>
  )
}

export default category

export const pageQuery = graphql`
  query CategoryQuery($slug: String!) {
    page: contentfulCategoryPage(slug: { eq: $slug }) {
      title
      description
      metaTitle
      metaDescription
      category {
        title
      }
    }
    projects: allContentfulProject {
      nodes {
        title
        metaTitle
        metaDescription
        slug
        imageHeight
        order
        category {
          slug
          title
        }
        thumbnails {
          fluid(maxWidth: 1104 quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`
