import React from "react";
import colors from "../../styles/colors";
import styled from "styled-components";
import fonts from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";

const Title = styled.h1`
  color: ${colors.darkGrey};
  text-transform: uppercase;
  ${fonts.ralewayRegular};
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 9.6px;
  margin-bottom: 20px;
  
  @media(min-width: ${breakpoints.md}){
    font-size: 48px;
    line-height: 62px;
    letter-spacing: 8.4px;
    margin-bottom: 22px;
  }
`;

const Description = styled.div`
  ${fonts.galvjiRegular};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.032px;
  color: ${colors.grey};
`;

const Intro = ({ title, description }) => {
  return (
    <React.Fragment>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </React.Fragment>
  )
}

export default Intro;