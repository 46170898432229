import React, { useState, useRef } from "react"
import {Container, Row, Col} from "styled-bootstrap-grid";
import Img from "gatsby-image";
import UnderlineButton from "../Core/UnderlineButton";
import styled from "styled-components";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";
import { Link } from "gatsby";

const ProjectCard = styled.div`
  margin-top: 90px;
  @media(min-width: ${breakpoints.md}){
    margin-top: 180px;
  }
`;

const LinkContainer = styled.div`

`;

const Title = styled.h3`
  font-size: 24px;
  line-height: 34px;
  ${fonts.ralewayRegular};
  color: ${colors.darkGrey};
  text-transform: uppercase;
  margin-bottom: 30px;
  margin-top: 10px;
  letter-spacing: 7.2px;
  box-sizing: border-box;
  
  @media(min-width: ${breakpoints.md}){
    letter-spacing: 4.2px;
    margin-bottom: 40px;
    max-width: 350px;
  }
  @media(min-width: ${breakpoints.lg}){
    letter-spacing: 9.6px;
  }
`;

const Category = styled.span`
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.032px;
  ${fonts.galvjiRegular};
  color: ${colors.lightGold};
  margin-top: 20px;
  a {
    color: ${colors.lightGold};
    text-decoration: none;
  }
  @media(min-width: ${breakpoints.md}){
    margin-top: 0;
  }
`;

const Details = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  background: ${colors.backgroundGrey};
  background: ${props => props.hovered ? colors.lightGold : colors.backgroundGrey};
  transition: .2s;
  position: absolute;
  top: -20px;
  right: -20px;
  
  @media(min-width: ${breakpoints.md}){
    top: -30px;
    right: -30px;
  }
`;

const ColStyled = styled(Col)`
  margin-right: 20px;
  
  @media(min-width: ${breakpoints.md}){
    margin-right: 0;
  }
`;

const ImageContainer = styled.div`
  height: ${props => props.long ? "589px" : "394px"};
  max-width: ${props => props.long ? "456px" : "552px" };
  position: relative;
  @media(min-width: ${breakpoints.md}){
    height: ${props => props.long ? "500px" : "250px"};
    margin-right: 0;
  }
  @media(min-width: ${breakpoints.lg}){
    height: ${props => props.long ? "589px" : "394px"};
    margin-right: 0;
  }
  @media(min-width: ${breakpoints.max}){
    max-width: ${props => props.long ? "556px" : "652px" };
    height: ${props => props.long ? "689px" : "394px"};
    margin-right: 0;
  }

`

const AlternateImages = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
`

const ImgStyled = styled(Img)`
  height: 100%;
  opacity: ${props => props.visible ? 1 : 0};
  transition: opacity 0.2s ease-out;


`

const MainImage = styled(Img)`
  height: 100%;
  transition: opacity 0.2s ease-out;


`

const AlternateImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`

const RowStyled = styled(Row)`
  max-width: ${breakpoints.xxl};
  margin: auto;
`


const Project = ({ isEven, project}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [visible, setVisible] = useState(0);
  let indexCount = 0
  const intervalRef = useRef(null)

  const cycleImages = () => {
    indexCount = indexCount < project.thumbnails.length ? indexCount : 0;
    setVisible(indexCount)
    indexCount++;
  }


  const handleMouseOver = () => {
    setIsHovered(true);
    intervalRef.current = setInterval(cycleImages, 1000)
  }

  const handleMouseLeave = () => {
      setIsHovered(false);
      clearInterval(intervalRef.current);
      setVisible(0)
  }


  return (
    <ProjectCard>
      <Container fluid>
        <RowStyled mdJustifyContent={isEven ? "start" : "end"}>
          <ColStyled md={6} lg={6} mdOrder={isEven ? 2 : 1} smOrder={1} >
            <div onMouseEnter={() => handleMouseOver()} onMouseLeave={() => handleMouseLeave()}>
              <Link to={`/projet/` + project.slug}>
                <ImageContainer long={!project.imageHeight}>
                    <Overlay hovered={isHovered}/>
                    <MainImage fluid={project.thumbnails[0].fluid} alt={project.title}/>
                    {project.thumbnails.map((image, index) => {
                      return (
                        index !== 0 && (
                          <AlternateImages key={index}>
                          <AlternateImageContainer long={project.imageHeight} >
                            <ImgStyled fluid={image.fluid} alt={project.title} long={project.imageHeight} visible={visible === index}/>
                          </AlternateImageContainer>
                      </AlternateImages>
                        )
                      )
                    })}
                </ImageContainer>
              </Link>
            </div>
          </ColStyled>
          <Col md={project.imageHeight? 4 : 5} mdOrder={isEven ? 1 : 2} smOrder={1} mdOffset={1}>
            <Details>
              <Category>
                <Link to={`/${project.category.slug}`}>
                  {project.category.title}
                </Link>
              </Category>
              <Title>{project.title}</Title>
              <LinkContainer>
                <UnderlineButton to={`/projet/` + project.slug} gold={1}>Voir le projet</UnderlineButton>
              </LinkContainer>
            </Details>
          </Col>
        </RowStyled>
      </Container>
    </ProjectCard>
  )
}

export default Project;
