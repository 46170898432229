import React from "react";
import styled from 'styled-components';
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints"

const StyledTitle = styled.h3`
  ${fonts.ralewayRegular};
  font-size: 20px;
  line-height: 60px;
  text-transform: uppercase;
  text-align: center;
  color: ${colors.darkGold};
  letter-spacing: 12px; //12px=600 
  
  a {
    color: ${colors.lightGold};
    text-decoration: none;
  }
  
  @media(min-width: ${breakpoints.sm}){
    font-size: 30px;
    line-height: 90px;
    letter-spacing: 9px;
  }
`;

const DarkTitle = ({children}) => {
  return (
    <StyledTitle>
      {children}
    </StyledTitle>
  )
}

export default DarkTitle